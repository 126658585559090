/* General Container Styling */
.about-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  /* Header Section */
  .about-header {
    text-align: start;
    margin-bottom: 2rem;
  }
  
  .about-header h1 {
    font-size: 2.5rem;
    color: #063C89;
  }
  
  .about-header p {
    font-size: 1.2rem;
    margin-top: 1rem;
    color: #555;
  }
  
  /* Section Styles */
  .about-section {
    margin-bottom: 2rem;
  }
  
  .about-section h2 {
    font-size: 2rem;
    color: #063C89;
    margin-bottom: 1rem;
  }
  
  .about-section p, .about-section ul {
    font-size: 1.1rem;
    color: #444;
    margin-bottom: 1rem;
  }
  
  .about-section ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .about-section ul li {
    margin-bottom: 0.5rem;
  }
  
  .about-section ul li strong {
    color: #063C89;
  }
  
  /* Call to Action Section */
  .about-cta {
    text-align: center;
    padding: 2rem;
    background-color: #F9F9F9;
    border-radius: 0.5rem;
  }
  
  .about-cta h2 {
    font-size: 2rem;
    color: #063C89;
  }
  
  .about-cta p {
    font-size: 1.2rem;
    margin-top: 1rem;
  }
  
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .about-header h1 {
      font-size: 2.2rem;
    }
  
    .about-section h2 {
      font-size: 1.8rem;
    }
  
    .about-cta h2 {
      font-size: 1.8rem;
    }
  }
  
  @media (max-width: 768px) {
    .about-container {
      padding: 1rem;
    }
  
    .about-header h1 {
      font-size: 2rem;
    }
  
    .about-section h2 {
      font-size: 1.6rem;
    }
  
    .about-cta h2 {
      font-size: 1.6rem;
    }
  }
  
  @media (max-width: 480px) {
    .about-header h1 {
      font-size: 1.8rem;
    }
  
    .about-header p {
      font-size: 1rem;
    }
  
    .about-section h2 {
      font-size: 1.4rem;
    }
  
    .about-section p, .about-section ul {
      font-size: 1rem;
    }
  
    .about-cta h2 {
      font-size: 1.4rem;
    }
  
    .about-cta p {
      font-size: 1rem;
    }
  }
  