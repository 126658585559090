.contact-container {
  color: #333;
  position: relative;
}

.contact-header {
  padding: 1rem 2rem;
  text-align: center;
}

.contact-page-title {
  font-size: 2rem; /* Reduced title size */
  color: #0a3174;
  font-weight: 600;
}

.contact-page-text {
  font-size: 1rem; /* Slightly smaller text */
  color: #4d4d4d;
  margin-top: 0.5rem;
  max-width: 750px; /* Slightly narrower width */
  margin-left: auto;
  margin-right: auto;
  line-height: 1.4; /* More compact line height */
}

.blue-bg-with-icons {
  background-color: #0a3174;
  padding: 2rem 1rem; /* Reduced padding */
  text-align: center;
  min-height: 18rem; /* Slightly smaller height */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 1.5rem; /* Reduced margin */
}

.contact-item {
  color: #ecf0f1;
  text-align: center;
  flex-basis: 200px; /* Smaller contact items */
  margin: 0.5rem 0;
}

.contact-item p {
  font-size: 1rem; /* Slightly smaller font size */
  font-weight: 500;
}

.contact-icon {
  width: 50px; /* Smaller icons */
  height: 50px;
  border-radius: 50%;
  /* background-color: #e78900; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 0.8rem;
}

.white-bg-container {
  background-color: white;
  padding: 4rem 1rem;
  display: flex;
  justify-content: center;
  position: relative;
}

.contact-form-container3 {
  background-color: #f9f9f9;
  padding: 1.5rem 2rem; /* Reduced padding */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08); /* Softer shadow */
  border-radius: 8px;
  max-width: 700px; /* Slightly narrower form container */
  width: 100%;
  position: absolute;
  top: -3rem;
}

.contact-form-container3 h2 {
  text-align: center;
  color: #0a3174;
  font-size: 1.8rem; /* Reduced heading size */
  margin-bottom: 0.5rem;
}

.contact-form-container3 p {
  text-align: center;
  color: #000;
  margin-bottom: 1.5rem;
}

.contact-form3 .form-row3 {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem; /* Reduced gap between inputs */
  margin-bottom: 1rem;
}

.contact-form3 .form-group3 {
  flex: 1;
  min-width: 180px; /* Slightly smaller width for inputs */
}

.contact-form3 label {
  display: block;
  font-size: 0.9rem; /* Slightly smaller label */
  margin-bottom: 0.4rem;
  color: #000;
  font-weight: 500;
}

.contact-form3 input,
.contact-form3 textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 0.95rem; /* Reduced font size */
  color: #2c3e50;
  background-color: #fff;
}

.contact-form3 textarea {
  resize: vertical;
  height: 100px; /* Reduced textarea height */
}

.submit_button_div {
  text-align: center;
  margin-top: 1.5rem; /* Reduced margin */
}

.submit_button3 {
  background-color: #063c89;
  color: #fff;
  padding: 0.6rem 1.5rem; /* Slightly smaller button */
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 6px;
}

.submit_button3:hover {
  background-color: #e78900;
}

.privacy-text {
  font-size: 0.75rem; /* Smaller privacy text */
  color: #7f8c8d;
  margin-top: 0.8rem;
  text-align: center;
}

/* Responsive styles for smaller screens */
@media (max-width: 1024px) {
  .contact-header {
    padding: 1rem 1.5rem;
  }

  .contact-info {
    gap: 1rem;
  }

  .contact-form-container3 {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .contact-header {
    padding: 1rem 1.5rem;
  }

  .contact-page-title {
    font-size: 1.6rem; /* Smaller for mobile */
  }

  .contact-page-text {
    font-size: 0.9rem;
  }

  .contact-form-container3 {
    padding: 1.5rem 1rem;
  }

  .contact-form3 .form-row3 {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .contact-header {
    padding: 0.8rem 1rem;
  }

  .contact-page-title {
    font-size: 1.5rem;
  }

  .contact-page-text {
    font-size: 0.85rem;
  }

  .contact-form-container3 {
    padding: 1rem 0.8rem;
  }

  .submit_button3 {
    font-size: 0.9rem; /* Smaller button for mobile */
  }
}
