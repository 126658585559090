.content1-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 8rem;
  height: 60vh;
  box-sizing: border-box;
}

.content {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image_container {
  flex: 1;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_container img {
  max-width: 40%;
  height: auto;
  object-fit: cover;
  border-radius: 0.5rem;
}

.head_line {
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 1.2;
  color: #063c89;
  margin-bottom: 1rem;
}

.sub_head_line {
  font-size: 1.4rem;
  font-weight: 400;
  color: #333;
  margin-bottom: 1rem;
}

.cta_line span {
  font-size: 1.1rem;
}

.cta_group {
  display: flex;
  gap: 1rem;
}

.cta_button {
  padding: 0.5rem 1rem;
  color: white;
  background-color: #063c89;
  margin: 0.5rem 0rem;
  width: 6rem;
  text-align: center;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .content1-wrapper {
    height: 70vh;
    padding: 2rem 4rem;
  }

  .head_line {
    font-size: 2.5rem;
  }

  .sub_head_line {
    font-size: 1.2rem;
  }

  .cta_line span {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .content1-wrapper {
    flex-direction: column-reverse;
    text-align: center;
    height: auto;
    padding: 2rem 3rem;
  }

  .content,
  .image_container {
    width: 100%;
  }
 

  .image_container img {
    max-width: 40%;
 

  }

  .head_line {
    font-size: 2rem;
  }

  .sub_head_line {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .content1-wrapper {
    padding: 1rem;
  }

  .head_line {
    font-size: 1.8rem;
  }

  .sub_head_line {
    font-size: 1rem;
  }

  .cta_line span {
    font-size: 0.8rem;
  }

  .cta_group {
    flex-direction: column;
  }

  .cta_button {
    width: 100%;
  }
}
